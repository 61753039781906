import {
  ArrayField,
  ArrayInput,
  Button,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  Filter,
  RecordContextProvider,
  ImageField,
  ImageInput,
  List,
  NumberInput,
  Pagination,
  ReferenceField,
  ReferenceManyCount,
  ReferenceManyField,
  RichTextField,
  Show,
  SimpleForm,
  SimpleFormIterator,
  TabbedShowLayout,
  TextField,
  TextInput,
  useGetList,
  useRecordContext,
  useRedirect,
  BooleanInput,
} from "react-admin";

import { Box, Divider, Grid, Typography } from "@mui/material";

import { RichTextInput } from "ra-input-rich-text";

const transformEventData = (data: any) => {
  if (data.additionalImages && data.additionalImages.length > 0) {
    data.additionalImages = data.additionalImages.map((image: any) => {
      if (typeof image.src === "string") {
        return image;
      }
      if (!image.src?.src) {
        return null;
      }
      return {
        src: image.src.src, // image.src is already a URL string
        title: image.title, // title should be top-level
      };
    });
  }
  return data;
};

const EventFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <BooleanInput source="isActive" label="Active" alwaysOn />
  </Filter>
);

export const EventList = (props: any) => {
  return (
    <List {...props} filters={<EventFilter />}>
      <Datagrid rowClick="show">
        <ImageField source="mainImage.src" label="Main Image" />
        <TextField source="name" label="Event Name" />
        <RecordContextProvider>
          <NextEventInstanceField />
        </RecordContextProvider>
        <ReferenceManyCount
          label="Sold"
          reference="passes"
          target="eventId"
          filter={{ status: "pending" }}
        />
        <EditButton label="" />
      </Datagrid>
    </List>
  );
};

const NextEventInstanceField = ({ record }: { record?: any }) => {
  const { data: instances, isLoading } = useGetList("eventInstances", {
    pagination: { page: 1, perPage: 1 },
    sort: { field: "startDate", order: "ASC" },
    filter: { eventId: record?.id },
  });

  if (isLoading || !instances || instances.length === 0) {
    return <span>No upcoming instances</span>;
  }

  const nextInstance = instances[0];
  return <DateField record={nextInstance} source="startDate" showTime />;
};

export const EventShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="summary">
        <TextField source="id" />
        <TextField source="name" />
        <ImageField source="mainImage.src" label="Main Image" />

        <ArrayField source="additionalImages" label="Additional Images">
          <Datagrid>
            <ImageField source="src" />
            <TextField source="title" />
          </Datagrid>
        </ArrayField>
        <TextField source="summary" />
        <RichTextField source="description" label="Event Description" />
        <ArrayField source="tickets" label="Tickets">
          <Datagrid>
            <TextField source="id" label="Ticket ID" />
            <TextField source="name" label="Ticket Name" />
            <TextField source="description" label="Ticket Description" />
            <TextField source="amount" label="Price in cents" />
          </Datagrid>
        </ArrayField>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="instances">
        <ReferenceManyField reference="eventInstances" target="eventId">
          <Datagrid>
            <DateField source="startDate" label="Starts At" showTime />
            <DateField source="endDate" label="Ends At" showTime />
            <TextField source="description" label="Description" />
          </Datagrid>
          <Pagination />
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Sales">
        <ReferenceManyField
          label="Sold"
          reference="passes"
          target="eventId"
          filter={{ status: "pending" }}
        >
          <Datagrid>
            <DateField source="createdate" />
            <TextField source="name" />
            <ReferenceField source="memberId" reference="members">
              <TextField source="displayName" />
              <TextField source="email" />
            </ReferenceField>
            <TextField source="amount" />
            <TextField source="status" />
          </Datagrid>
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);

export const EventCreate = (props: any) => (
  <Create {...props} transform={transformEventData}>
    <SimpleForm>
      <TextInput
        source="id"
        label="Event Slug"
        required
        helperText="unique-name-of-event"
      />
      <TextInput source="name" label="Event Name" fullWidth />
      <BooleanInput source="isActive" />

      <ImageInput source="mainImage" label="Main Image" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>

      <ArrayInput source="additionalImages" label="Additional Images">
        <SimpleFormIterator>
          <ImageInput source="src" label="Image" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source="title" label="Image Title" />
        </SimpleFormIterator>
      </ArrayInput>

      <TextInput source="summary" fullWidth />
      <RichTextInput source="description" label="Event Description" fullWidth />
      <Divider sx={{ width: "100%" }} />
      <TextInput source="location.name" label="Location Name" />
      <TextInput source="location.address" label="Location Address" />
      <Box
        sx={{
          p: 2,
          mt: 2,
          mb: 2,
          width: "100%",
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Tickets
        </Typography>
        <ArrayInput source="tickets" label="">
          <SimpleFormIterator>
            <TextInput source="id" label="Ticket ID" />
            <TextInput source="name" label="Ticket Name" />
            <TextInput source="description" label="Ticket Description" />
            <NumberInput source="amount" label="Price in cents" />
            <TextInput source="productType" label="" defaultValue={"ticket"} />
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
      <DateInput source="createdate" defaultValue={new Date()} disabled />
      <DateInput source="lastupdate" defaultValue={new Date()} disabled />
    </SimpleForm>
  </Create>
);

const CreateEventInstanceButton = () => {
  const record = useRecordContext();
  const redirect = useRedirect();

  const handleClick = () => {
    redirect("create", "eventInstances", record.id);
  };

  return (
    <Button
      onClick={handleClick}
      label="Add Instance"
      variant="contained"
    ></Button>
  );
};

export const EventEdit = (props: any) => {
  return (
    <Edit {...props} transform={transformEventData}>
      <SimpleForm sanitizeEmptyValues>
        <TextInput disabled source="id" label="Event Slug" />
        <BooleanInput source="isActive" />
        <TextInput source="name" label="Event Name" fullWidth />
        {/* Main Image */}
        <ImageInput source="mainImage" label="Main Image" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>

        <ArrayInput source="additionalImages" label="Additional Images">
          <SimpleFormIterator>
            <ImageInput source="src" label="Image" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput source="title" label="Image Title" />
          </SimpleFormIterator>
        </ArrayInput>

        <TextInput source="summary" fullWidth />
        <RichTextInput
          source="description"
          label="Event Description"
          defaultValue=""
          fullWidth
        />
        <TextInput source="location.name" label="Location Name" fullWidth />
        <TextInput
          source="location.address"
          label="Location Address"
          fullWidth
        />
        <Box
          sx={{
            p: 2,
            mt: 2,
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Instances
          </Typography>
          <Divider sx={{ width: "100%" }} />
          <ReferenceManyField reference="eventInstances" target="eventId">
            <Box>
              <Datagrid>
                <DateField source="startDate" label="Starts At" showTime />
                <DateField source="endDate" label="Ends At" showTime />
                <TextField source="description" label="Description" />
                <EditButton />
              </Datagrid>
              <CreateEventInstanceButton />
            </Box>
          </ReferenceManyField>
        </Box>
        <Box
          sx={{
            p: 2,
            mt: 2,
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Tickets
          </Typography>
          <ArrayInput source="tickets" label="">
            <SimpleFormIterator inline>
              <TextInput source="id" label="Ticket ID" />
              <TextInput source="name" label="Ticket Name" />
              <TextInput source="description" label="Ticket Description" />
              <NumberInput source="amount" label="Price" />
              <TextInput source="productType" defaultValue="ticket" disabled />
            </SimpleFormIterator>
          </ArrayInput>
        </Box>
        <Divider sx={{ width: "100%" }} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DateInput disabled source="createdate" />
          </Grid>
          <Grid item xs={6}>
            <DateInput disabled source="lastupdate" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const EventsAdmin = {
  list: EventList,
  show: EventShow,
  create: EventCreate,
  edit: EventEdit,
};
export default EventsAdmin;
