import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import { Admin, Resource } from "react-admin";
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from "react-admin-firebase";

import AirplaneTicket from "@mui/icons-material/AirplaneTicket";
import CheckInsIcon from "@mui/icons-material/TaskAlt";
import CheckinsAdmin from "./pages/CheckinsAdmin";
import ClubInfoAdmin from "./pages/ClubInfoAdmin";
import { ClubLayout } from "./components/ClubLayout";
import CustomLoginPage from "./components/CustomLoginPage";
import Dashboard from "./pages/Dashboard";
import DiscountIcon from "@mui/icons-material/Discount";
import DiscountsAdmin from "./pages/DiscountsAdmin";
import EventIcon from "@mui/icons-material/Event";
import EventMonitor from "./components/EventMonitor";
import EventsAdmin from "./pages/EventsAdmin";
import EventInstancesAdmin from "./pages/EventInstancesAdmin";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MembersAdmin from "./pages/MembersAdmin";
import MembersIcon from "@mui/icons-material/SupervisedUserCircle";
import OrdersAdmin from "./pages/OrdersAdmin";
import OrdersIcon from "@mui/icons-material/PointOfSale";
import PassAdmin from "./pages/PassAdmin";
import PassIcon from "@mui/icons-material/Loyalty";
import PassTemplatesAdmin from "./pages/PassTemplatesAdmin";
import ProductsAdmin from "./pages/ProductsAdmin";
import ProductsIcon from "@mui/icons-material/ShoppingCart";
import VendorsAdmin from "./pages/VendorAdmin";
import Storefront from "@mui/icons-material/Storefront";
import firebase from "firebase/compat/app";

const firebaseConfig = {
  apiKey: "AIzaSyDUiwqz1GD3XEbGnhjc6WizsDeiRGmUdu8",
  authDomain: "manageyourclub-973b2.firebaseapp.com",
  projectId: "manageyourclub-973b2",
  storageBucket: "manageyourclub-973b2.appspot.com",
  messagingSenderId: "1010568760739",
  appId: "1:1010568760739:web:fc75d4a25be1332d189e16",
  measurementId: "G-S1YWETF3XM",
};

const club_id = "cola-stl";

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const storage = firebase.storage(firebaseApp);

const authProvider = FirebaseAuthProvider(firebaseConfig, {});
const dataProvider = FirebaseDataProvider(firebaseConfig, {
  logging: true,
  rootRef: `clubs/${club_id}/`,
  app: firebaseApp,
  persistence: "local",
  disableMeta: true,
  transformToDb(resourceName, documentData, documentId) {
    Object.keys(documentData).forEach(function (key) {
      if (typeof documentData[key] === typeof undefined) {
        delete documentData[key];
      }
    });
    return documentData;
  },
});

const theme = {
  palette: {
    mode: "dark",
    primary: {
      main: "#FFF7E3",
    },
    secondary: {
      main: "#D6BDA3",
    },
    background: {
      default: "#001827",
      paper: "#001827",
    },
  },
};

function App() {
  return (
    <Admin
      title={`ManageYour.Club - The Cola St. Louis`}
      dashboard={Dashboard}
      loginPage={CustomLoginPage}
      layout={ClubLayout}
      dataProvider={dataProvider}
      authProvider={authProvider}
      theme={theme}
    >
      <Resource name="members" icon={MembersIcon} {...MembersAdmin} />
      <Resource name="events" icon={EventIcon} {...EventsAdmin} />
      <Resource name="eventInstances" {...EventInstancesAdmin} />
      <Resource name="orders" icon={OrdersIcon} {...OrdersAdmin} />
      <Resource name="passes" icon={PassIcon} {...PassAdmin} />
      <Resource name="checkins" icon={CheckInsIcon} {...CheckinsAdmin} />
      <Resource name="vendors" icon={Storefront} {...VendorsAdmin} />
      <Resource
        name="config/v1/info"
        options={{ label: "Club Info" }}
        icon={LocationOnIcon}
        {...ClubInfoAdmin}
      />
      <Resource
        name="config/v1/discounts"
        options={{ label: "Discounts" }}
        icon={DiscountIcon}
        {...DiscountsAdmin}
      />
      <Resource
        name="config/v1/products"
        options={{ label: "Products" }}
        icon={ProductsIcon}
        {...ProductsAdmin}
      />
      <Resource
        name="config/v1/passtemplates"
        options={{ label: "Pass Templates" }}
        icon={AirplaneTicket}
        {...PassTemplatesAdmin}
      />
      {/* <Resource icon={QuestionIcon} name="questions" {...QuestionsAdmin} />
      <Resource icon={AnswersIcon} name="answers" {...AnswersAdmin} /> */}

      <EventMonitor />
    </Admin>
  );
}

export default App;
